import React from 'react'
import { Product } from "../components"

const Products = () => {
  return (
    <>
      <Product />
    </>
  )
}

export default Products