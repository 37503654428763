import { Main } from "../components";

function Home() {
  return (
    <>
      <Main />
    </>
  )
}

export default Home